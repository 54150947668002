@import '~vue-croppa/dist/vue-croppa.css'
// app global css
*{text-transform: none;}
//.q-skeleton-i18n{
//  float:left;
//}
.sc-line{
  line-height:48px;
}
.no-break{
  word-break keep-all
  white-space nowrap
}
.flex-1{
  flex:1;
}
.flex-2{
  flex:2;
}
.flex-0,.flex-none{
  flex:none;
}
.flex-center{
  display flex
  justify-content: center;
  align-items: center;
}
.flex-sides{
  display:flex;
  justify-content:space-between;
  align-items: center;
}
.flex-aligm-center{
  display:flex;
  align-items:center
}
.text-12
  font-size 12px
.text-14
  font-size 14px
.q-stepper__dot
  width 24px
  flex none
.q-skeleton-i18n{
  float left
  display inline-block
}
.q-skeleton-i18n:only-child{
  float: none;
}
.q-skeleton-i18n ~ .q-skeleton-i18n{
  margin-left:3px;
}
.q-field__prefix{
  padding-left:6px;
}
.q-breadcrumbs
  font-size 12px

.q-tabs-head .q-tab
  font-size 12px
  .q-tab-icon
    font-size 14px
//mostly for sc-neston
.q-field__append
  padding-left 0px
  padding-right 12px

#leftMenu
  .q-item
    min-height 28px
    padding-top 0
    padding-bottom 0
  .q-item,.q-icon
    font-size 14px
  .q-item-section
    min-width 22px
  .q-item-label
    margin-left 0
  .q-expansion-item-sub-item
    padding-left 16px
.dashboardGauge
  height:220px !important
  //overflow: hidden;
  //ECharts
  //  height:250px !important
  ////clip: rect(0px,100px,100px,0px)
  ////overflow: hidden;
  ////canvas
  //  height:250px;

.q-page-sticky .q-btn
  width 3em
  height 3em
.break-all
  word-break break-all
  word-wrap break-word
  white-space normal


.sdTable
  border-collapse:collapse;
  border-spacing 0
  border-left 1px solid #000
  border-top 1px solid #000
  th
    text-align center
    font-weight bold
    user-select all
  & td,th
    padding:3px;
    margin: 0
    height 18px !important;
    word-break break-all
    word-wrap break-word
    white-space:normal;

    border-right 1px solid #000
    border-bottom 1px solid #000
.fs14
  font-size 14px
.fs12
  font-size 12px

.q-bar--standard //sc-dialog title bar
  height auto
  min-height 18px;
.prevent{
  pointer-events:none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.text-mini{
  -webkit-transform: scale(0.75);transform: scale(0.75);transform-origin: center left;font-size:12px;
}
.margin-top10{
  margin-top:10px
}
.q-field__append{
  padding-right 6px
}
.cursor{//鼠标引入手指
  cursor:pointer;
}
.hover{
  :hover{
    color:#1698c9
  }
}
.text-justify{
  text-align: justify;
  word-break: break-all;
}
//图片缩放回模糊
.img-rend{    
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges; /* Firefox */     
  image-rendering: -o-crisp-edges; /* Opera */      
  //image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */ image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
//-超出以....
.ellipsis{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:wrap;
}
.break{
  word-wrap:break-word;
  word-break:normal
}
.overflow-text {

  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏溢出文本 */
  text-overflow: ellipsis; /* 使用省略号代替溢出文本 */
  display: inline-block; /* 使文本容器能够根据内容自动调整大小 */
}
//-表格
.useTable{
  border-collapse: collapse;
  border-spacing: 0;
  td,th {
    padding: 0;
  }
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;
  caption {
    color: #000;
    font: italic 85%/1 arial,sans-serif;
    padding: 1em 0;
    text-align: center;
  }
  td,th {
    border-left: 1px solid #cbcbcb;
    border-width: 0 0 0 1px;
    font-size: inherit;
    margin: 0;
    overflow: visible;
    padding: .5em 1em;
  }
  thead {
    background-color: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom;
  }
  td {
    background-color: transparent;
  }
  td {
    border-bottom: 1px solid #cbcbcb;
  }
  tbody>tr:last-child>td {
    border-bottom-width: 0;
  }
}
//上传按钮
.m-btn{
  line-height:30px;
  background : #1698c9;
  border-radius:3px;
  font-size:12px;
  color:white;
  text-align:center;
  position:relative;
}
.m-btn input{
  width:100%;
  opacity:0;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  cursor:pointer;
}