//都带间隙+周边留白
gap = 6px
.flex-wrap
  display inline-flex
  flex-wrap:wrap;
  width 100%
  align-content: flex-start
  //transform: translate(5px, 0px);
  >.flex-wrap
    display flex
.flex-wrap.column
  flex-direction:column;
  >*
    position relative
    top -(gap/2)
    left -(gap/2)
.flex-wrap.column.right-align//最复杂的表达
  align-content: flex-end
  >*
    left gap
.flex-wrap.column.right//最复杂的表达
  flex-wrap:wrap-reverse;
  >*
    left gap
.flex-wrap
  >*
    flex 1 1 auto
    margin (gap/2)
    word-break break-all
    word-wrap break-word


.flex-wrap.inline
  >*
    flex none
    position relative
    top -(gap/2)
    left -(gap/2)

.flex-wrap.inline.right//最复杂的表达
  justify-content flex-end
  &.column
    justify-content flex-start
  >*
    left (gap/2)
.flex-wrap.reverse
  for num,i in (1...100)
    od = 100 - num + 1
    :nth-child({num})
      order od
.flex-wrap,.flex
  >.break
    flex-basis: 100%;
    height: 0;
    margin 0
.flex-wrap.padding
  padding gap

