.flex-wrap {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: flex-start;
}
.flex-wrap >.flex-wrap {
  display: flex;
}
.flex-wrap.column {
  flex-direction: column;
}
.flex-wrap.column >* {
  position: relative;
  top: -3px;
  left: -3px;
}
.flex-wrap.column.right-align {
  align-content: flex-end;
}
.flex-wrap.column.right-align >* {
  left: 6px;
}
.flex-wrap.column.right {
  flex-wrap: wrap-reverse;
}
.flex-wrap.column.right >* {
  left: 6px;
}
.flex-wrap >* {
  flex: 1 1 auto;
  margin: 3px;
  word-break: break-all;
  word-wrap: break-word;
}
.flex-wrap.inline >* {
  flex: none;
  position: relative;
  top: -3px;
  left: -3px;
}
.flex-wrap.inline.right {
  justify-content: flex-end;
}
.flex-wrap.inline.right.column {
  justify-content: flex-start;
}
.flex-wrap.inline.right >* {
  left: 3px;
}
.flex-wrap.reverse :nth-child(1) {
  order: 100;
}
.flex-wrap.reverse :nth-child(2) {
  order: 99;
}
.flex-wrap.reverse :nth-child(3) {
  order: 98;
}
.flex-wrap.reverse :nth-child(4) {
  order: 97;
}
.flex-wrap.reverse :nth-child(5) {
  order: 96;
}
.flex-wrap.reverse :nth-child(6) {
  order: 95;
}
.flex-wrap.reverse :nth-child(7) {
  order: 94;
}
.flex-wrap.reverse :nth-child(8) {
  order: 93;
}
.flex-wrap.reverse :nth-child(9) {
  order: 92;
}
.flex-wrap.reverse :nth-child(10) {
  order: 91;
}
.flex-wrap.reverse :nth-child(11) {
  order: 90;
}
.flex-wrap.reverse :nth-child(12) {
  order: 89;
}
.flex-wrap.reverse :nth-child(13) {
  order: 88;
}
.flex-wrap.reverse :nth-child(14) {
  order: 87;
}
.flex-wrap.reverse :nth-child(15) {
  order: 86;
}
.flex-wrap.reverse :nth-child(16) {
  order: 85;
}
.flex-wrap.reverse :nth-child(17) {
  order: 84;
}
.flex-wrap.reverse :nth-child(18) {
  order: 83;
}
.flex-wrap.reverse :nth-child(19) {
  order: 82;
}
.flex-wrap.reverse :nth-child(20) {
  order: 81;
}
.flex-wrap.reverse :nth-child(21) {
  order: 80;
}
.flex-wrap.reverse :nth-child(22) {
  order: 79;
}
.flex-wrap.reverse :nth-child(23) {
  order: 78;
}
.flex-wrap.reverse :nth-child(24) {
  order: 77;
}
.flex-wrap.reverse :nth-child(25) {
  order: 76;
}
.flex-wrap.reverse :nth-child(26) {
  order: 75;
}
.flex-wrap.reverse :nth-child(27) {
  order: 74;
}
.flex-wrap.reverse :nth-child(28) {
  order: 73;
}
.flex-wrap.reverse :nth-child(29) {
  order: 72;
}
.flex-wrap.reverse :nth-child(30) {
  order: 71;
}
.flex-wrap.reverse :nth-child(31) {
  order: 70;
}
.flex-wrap.reverse :nth-child(32) {
  order: 69;
}
.flex-wrap.reverse :nth-child(33) {
  order: 68;
}
.flex-wrap.reverse :nth-child(34) {
  order: 67;
}
.flex-wrap.reverse :nth-child(35) {
  order: 66;
}
.flex-wrap.reverse :nth-child(36) {
  order: 65;
}
.flex-wrap.reverse :nth-child(37) {
  order: 64;
}
.flex-wrap.reverse :nth-child(38) {
  order: 63;
}
.flex-wrap.reverse :nth-child(39) {
  order: 62;
}
.flex-wrap.reverse :nth-child(40) {
  order: 61;
}
.flex-wrap.reverse :nth-child(41) {
  order: 60;
}
.flex-wrap.reverse :nth-child(42) {
  order: 59;
}
.flex-wrap.reverse :nth-child(43) {
  order: 58;
}
.flex-wrap.reverse :nth-child(44) {
  order: 57;
}
.flex-wrap.reverse :nth-child(45) {
  order: 56;
}
.flex-wrap.reverse :nth-child(46) {
  order: 55;
}
.flex-wrap.reverse :nth-child(47) {
  order: 54;
}
.flex-wrap.reverse :nth-child(48) {
  order: 53;
}
.flex-wrap.reverse :nth-child(49) {
  order: 52;
}
.flex-wrap.reverse :nth-child(50) {
  order: 51;
}
.flex-wrap.reverse :nth-child(51) {
  order: 50;
}
.flex-wrap.reverse :nth-child(52) {
  order: 49;
}
.flex-wrap.reverse :nth-child(53) {
  order: 48;
}
.flex-wrap.reverse :nth-child(54) {
  order: 47;
}
.flex-wrap.reverse :nth-child(55) {
  order: 46;
}
.flex-wrap.reverse :nth-child(56) {
  order: 45;
}
.flex-wrap.reverse :nth-child(57) {
  order: 44;
}
.flex-wrap.reverse :nth-child(58) {
  order: 43;
}
.flex-wrap.reverse :nth-child(59) {
  order: 42;
}
.flex-wrap.reverse :nth-child(60) {
  order: 41;
}
.flex-wrap.reverse :nth-child(61) {
  order: 40;
}
.flex-wrap.reverse :nth-child(62) {
  order: 39;
}
.flex-wrap.reverse :nth-child(63) {
  order: 38;
}
.flex-wrap.reverse :nth-child(64) {
  order: 37;
}
.flex-wrap.reverse :nth-child(65) {
  order: 36;
}
.flex-wrap.reverse :nth-child(66) {
  order: 35;
}
.flex-wrap.reverse :nth-child(67) {
  order: 34;
}
.flex-wrap.reverse :nth-child(68) {
  order: 33;
}
.flex-wrap.reverse :nth-child(69) {
  order: 32;
}
.flex-wrap.reverse :nth-child(70) {
  order: 31;
}
.flex-wrap.reverse :nth-child(71) {
  order: 30;
}
.flex-wrap.reverse :nth-child(72) {
  order: 29;
}
.flex-wrap.reverse :nth-child(73) {
  order: 28;
}
.flex-wrap.reverse :nth-child(74) {
  order: 27;
}
.flex-wrap.reverse :nth-child(75) {
  order: 26;
}
.flex-wrap.reverse :nth-child(76) {
  order: 25;
}
.flex-wrap.reverse :nth-child(77) {
  order: 24;
}
.flex-wrap.reverse :nth-child(78) {
  order: 23;
}
.flex-wrap.reverse :nth-child(79) {
  order: 22;
}
.flex-wrap.reverse :nth-child(80) {
  order: 21;
}
.flex-wrap.reverse :nth-child(81) {
  order: 20;
}
.flex-wrap.reverse :nth-child(82) {
  order: 19;
}
.flex-wrap.reverse :nth-child(83) {
  order: 18;
}
.flex-wrap.reverse :nth-child(84) {
  order: 17;
}
.flex-wrap.reverse :nth-child(85) {
  order: 16;
}
.flex-wrap.reverse :nth-child(86) {
  order: 15;
}
.flex-wrap.reverse :nth-child(87) {
  order: 14;
}
.flex-wrap.reverse :nth-child(88) {
  order: 13;
}
.flex-wrap.reverse :nth-child(89) {
  order: 12;
}
.flex-wrap.reverse :nth-child(90) {
  order: 11;
}
.flex-wrap.reverse :nth-child(91) {
  order: 10;
}
.flex-wrap.reverse :nth-child(92) {
  order: 9;
}
.flex-wrap.reverse :nth-child(93) {
  order: 8;
}
.flex-wrap.reverse :nth-child(94) {
  order: 7;
}
.flex-wrap.reverse :nth-child(95) {
  order: 6;
}
.flex-wrap.reverse :nth-child(96) {
  order: 5;
}
.flex-wrap.reverse :nth-child(97) {
  order: 4;
}
.flex-wrap.reverse :nth-child(98) {
  order: 3;
}
.flex-wrap.reverse :nth-child(99) {
  order: 2;
}
.flex-wrap >.break,
.flex >.break {
  flex-basis: 100%;
  height: 0;
  margin: 0;
}
.flex-wrap.padding {
  padding: 6px;
}
/*# sourceMappingURL=src/css/flex-wrap.css.map */