
















.sc-item-v-align-top
  .q-item__section
    justify-content flex-start
.sc-item.text-right
  .sc-item-content
    text-align right
.sc-item.dense
  padding 0
  margin 0
  min-height 18px
  padding-right 18px
  .sc-item-label-comma
    width 7px
    overflow hidden
