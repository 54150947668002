@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'










.sc-pop-tooltip
  background #fff
  color #000;
  padding 0
  box-shadow: $menu-box-shadow
  border-radius: $generic-border-radius
  /*display none*/
