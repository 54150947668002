@import '~vue-croppa/dist/vue-croppa.css';
* {
  text-transform: none;
}
.sc-line {
  line-height: 48px;
}
.no-break {
  word-break: keep-all;
  white-space: nowrap;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-0,
.flex-none {
  flex: none;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-sides {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-aligm-center {
  display: flex;
  align-items: center;
}
.text-12 {
  font-size: 12px;
}
.text-14 {
  font-size: 14px;
}
.q-stepper__dot {
  width: 24px;
  flex: none;
}
.q-skeleton-i18n {
  float: left;
  display: inline-block;
}
.q-skeleton-i18n:only-child {
  float: none;
}
.q-skeleton-i18n ~ .q-skeleton-i18n {
  margin-left: 3px;
}
.q-field__prefix {
  padding-left: 6px;
}
.q-breadcrumbs {
  font-size: 12px;
}
.q-tabs-head .q-tab {
  font-size: 12px;
}
.q-tabs-head .q-tab .q-tab-icon {
  font-size: 14px;
}
.q-field__append {
  padding-left: 0px;
  padding-right: 12px;
}
#leftMenu .q-item {
  min-height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
#leftMenu .q-item,
#leftMenu .q-icon {
  font-size: 14px;
}
#leftMenu .q-item-section {
  min-width: 22px;
}
#leftMenu .q-item-label {
  margin-left: 0;
}
#leftMenu .q-expansion-item-sub-item {
  padding-left: 16px;
}
.dashboardGauge {
  height: 220px !important;
}
.q-page-sticky .q-btn {
  width: 3em;
  height: 3em;
}
.break-all {
  word-break: break-all;
  word-wrap: break-word;
  white-space: normal;
}
.sdTable {
  border-collapse: collapse;
  border-spacing: 0;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
}
.sdTable th {
  text-align: center;
  font-weight: bold;
  user-select: all;
}
.sdTable td,
.sdTable th {
  padding: 3px;
  margin: 0;
  height: 18px !important;
  word-break: break-all;
  word-wrap: break-word;
  white-space: normal;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
.fs14 {
  font-size: 14px;
}
.fs12 {
  font-size: 12px;
}
.q-bar--standard {
  height: auto;
  min-height: 18px;
}
.prevent {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.text-mini {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  transform-origin: center left;
  font-size: 12px;
}
.margin-top10 {
  margin-top: 10px;
}
.q-field__append {
  padding-right: 6px;
}
.cursor {
  cursor: pointer;
}
.hover :hover {
  color: #1698c9;
}
.text-justify {
  text-align: justify;
  word-break: break-all;
}
.img-rend {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}
.break {
  word-wrap: break-word;
  word-break: normal;
}
.overflow-text {
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏溢出文本 */
  text-overflow: ellipsis; /* 使用省略号代替溢出文本 */
  display: inline-block; /* 使文本容器能够根据内容自动调整大小 */
}
.useTable {
  border-collapse: collapse;
  border-spacing: 0;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;
}
.useTable td,
.useTable th {
  padding: 0;
}
.useTable caption {
  color: #000;
  font: italic 85%/1 arial, sans-serif;
  padding: 1em 0;
  text-align: center;
}
.useTable td,
.useTable th {
  border-left: 1px solid #cbcbcb;
  border-width: 0 0 0 1px;
  font-size: inherit;
  margin: 0;
  overflow: visible;
  padding: 0.5em 1em;
}
.useTable thead {
  background-color: #e0e0e0;
  color: #000;
  text-align: left;
  vertical-align: bottom;
}
.useTable td {
  background-color: transparent;
}
.useTable td {
  border-bottom: 1px solid #cbcbcb;
}
.useTable tbody>tr:last-child>td {
  border-bottom-width: 0;
}
.m-btn {
  line-height: 30px;
  background: #1698c9;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  position: relative;
}
.m-btn input {
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
/*# sourceMappingURL=src/css/app.css.map */